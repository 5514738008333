import React from 'react';
import { Radio } from 'antd';

import styles from './RadioGroup.module.scss';

const FormikRadioGroup = ({
  field, name, options, children, wrapperStyles, labelClassName, size = 'small', ...props
}) => {
  const fieldName = name || field.name;
  return (
    <Radio.Group
      {...field}
      {...props}
      name={fieldName}
      size={size}
      className={`
        ${styles.radioGroup}
        ${wrapperStyles}
      `}
    >
      {options.map(({ value, label, disabled }) => (
        <Radio className={`${styles.radio} ${labelClassName}`} key={value} value={value} disabled={disabled}>
          {label}
        </Radio>
      ))}
      {children && <div style={{ width: '100%' }}>{children}</div>}
    </Radio.Group>
  );
};

export default FormikRadioGroup;
